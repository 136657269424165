@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  /* colores para difuntos */
  --d-primary-color: #4e2262;
  --d-secondary-color: #6d3a83;
  --d-tertiary-color: #f5c85d;
  --d-quaternary-color: #646464;
  --d-quinary-color: #d3c8d8;
  --d-senary-color: #a995b0;
  --d-septenary-color: #9869aa;
  --d-octonary-color: #f3f4f6;
}


@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url('/fonts/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BebasBook';
  src: url('/fonts/BebasNeue-Book.otf') format('opentype');
}

@font-face {
  font-family: 'BebasBold';
  src: url('/fonts/BebasNeue-Bold.otf') format('opentype');
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .bg-blue-truek{
    background-color: #0062ff;
  }
  .bg-yellow-trueke {
    background-color: #ffc900;
  }

  .text-blue-truek {
    color: #0062ff;
  }
}

.font-poppins {
  font-family: Poppins, system-ui, sans-serif;
}

.is__invalid-input {
  border-color: #e11d48;
}

.is__invalid-error {
  color: white;
  @apply px-1 pt-1 text-xs;
}

.gradient_color_button {
  background-image: linear-gradient(to right top, #0033ff, #006aff, #008dff, #00a8fb, #39bfe7);
}

.p-multiselect-header {
  background-color: rgb(159, 173, 187);
}

.p-datatable-thead th {
  background: rgb(159, 173, 187);
}

.p-multiselect-items {
  background-color: lightgray;
}

.p-multiselect-item.p-focus {
  background-color: lightgray;
}

.p-datatable-thead {
  background: lightgray;
}


/* para el slider custom */

.slider-wrapper {
  display: flex;
  width: auto;
  transition: transform 0.5s ease;
  flex-shrink: 0;
}

.item-image {
  width: 100%;
  height: auto;
  display: block;
}

.slider-container.active {
  cursor: grabbing;
}

input[type] {
  min-height: 3rem;
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-carousel-item {
  inset: 0;
  margin: auto;
}

.marker {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute !important;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  font-size: 0.75rem;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  min-width: 3rem;
  max-width: 5rem;
  bottom: 100%;
  left: 50%;
  margin-left: -20px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.mapboxgl-ctrl-attrib-inner{
  display: none;
}

.p-component-overlay {
  overflow: hidden;
}


.image-miniature {
  background-color: var(--d-octonary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.375rem;
  border-color: #e5e7eb;
  cursor: pointer;
}

.video-miniature {
  background-color: var(--d-octonary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.375rem;
  border-color: #e5e7eb;
  cursor: pointer;
  z-index: 1;

}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-carrusel {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.carousel-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-height: 70vh;
}

button.prev,
button.next {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1001;
}

button.prev {
  left: 0;
}

button.next {
  right: 0;
}


@media (max-width: 426px) {

  .special {
    grid-row: 4 / 5;
    grid-column: 1 / -1;
    overflow: hidden;
}

  .thumbnails {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }

      button.prev,
      button.next {
          padding: 0.5rem 0.75rem;
      }
}

@font-face {
  font-family: "Amalfi Coast";

  src:
    url("https://db.onlinewebfonts.com/t/c8191b4eb6c3ff319f508516376e60df.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/c8191b4eb6c3ff319f508516376e60df.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/c8191b4eb6c3ff319f508516376e60df.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/c8191b4eb6c3ff319f508516376e60df.svg#Amalfi Coast")format("svg");
}


@font-face {
  font-family: "Gotham Bold";
  src: url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot");
  src: url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.svg#Gotham Bold")format("svg");
}


@font-face {
  font-family: "Gotham Book";
  src: url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.eot");
  src: url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/924ee4158324c02e3e0d62e54cafd14f.svg#Gotham Book")format("svg");
}
